import { Box, Button, Typography, Container } from "@mui/material";
import React from 'react'

const About = () => {
  return (
    <Typography>
      About Page 
    </Typography>
  )
}

export default About